import { useEffect, useState } from "react"
import axios from "axios"
import Splash from "./Splash"

let token = sessionStorage.getItem("token")

export default function Experience() {
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)

    function reRender () {
        setCount(count + 1)
    }

    function updateSite() {
        setUpdating(true)
        axios.post('https://backend.dmurenzi.com/experiences/', data, {
            headers: {
                'Authorization': token
            }
        })
        .then((res)=>{
            setUpdating(false)
            if (res.status == 200) {
                reRender()
            }
        })
    }

    useEffect(() => {
        const fetchData = () => {
            axios.get('https://backend.dmurenzi.com/experiences/')
            .then((response)=>{
                let data = response.data
                data.forEach(experience => {
                    experience.data = JSON.parse(experience.data)
                })
                setLoading(false)
                setData(data)
            })
        };

        fetchData();
    }, [])

    let dataFromServer = [
        {
            type: "",
            data : {
                title: "",
                dateFrom: "",
                dateTo: "",
                co: "",
                desc: ""
            }
        }
    ]
    const [data, setData] = useState(dataFromServer)


    function addData (type) {
        let info = data
        info.push({
            type: type,
            data : {
                title: null,
                dateFrom: null,
                dateTo: null,
                co: null,
                desc: null
            }
        })
        setData(info)
        reRender()
    }

    function updateData (key, newData) {
        let info = data
        info[key].data = newData
        setData(info)
        reRender()
    }

    function removeData (key) {
        let info = data
        info.splice(key, 1)
        setData(info)
        reRender()
    }

    function edExp (type, paramData, key) {
        let placeholder
        if (type == "education" || type == "qualification") {
            placeholder = "Institution"
        } else {
            placeholder = "Company/Organization"
        }

        let data = paramData

        return (
            <div className="ed-exp-edit" key={key}>
                <input
                    type="text"
                    className="ed-exp-title"
                    placeholder="Title"
                    value={data.title}
                    onChange={(e)=>{
                        data.title = e.target.value
                        updateData(key, data)
                    }}/>
                <div className="date-input-area">
                    <div className="date-input">
                        <label className="date-input-label">From</label>
                        <input
                            type="date"
                            className="ed-exp-date-from"
                            value={data.dateFrom}
                            onChange={(e)=>{
                                data.dateFrom = e.target.value
                                updateData(key, data)
                            }}
                        />
                    </div>
                    <div className="date-input">
                        <label className="date-input-label">To</label>
                        <input
                            type="date"
                            className="ed-exp-date-to"
                            value={data.dateTo}
                            onChange={(e)=>{
                                data.dateTo = e.target.value
                                updateData(key, data)
                            }}
                        />
                    </div>
                </div>
                <input
                    type="text"
                    className="ed-exp-title"
                    placeholder="Institution"
                    value={data.co}
                    onChange={(e)=>{
                        data.co = e.target.value
                        updateData(key, data)
                    }}
                />
                <textarea
                    className="ed-exp-desc"
                    placeholder={placeholder}
                    value={data.desc}
                    onChange={(e)=>{
                        data.desc = e.target.value
                        updateData(key, data)
                    }}
                />
                <button className="delete-button" onClick={()=>{removeData()}}>Delete</button>
            </div>
        )
    }

    function fillInData(type) {
        let inputDiv = []
        switch (type) {
            case "education":
                data.forEach(dataItem => {
                    if (dataItem.type == "education") {
                        inputDiv.push(edExp("education", dataItem.data, data.indexOf(dataItem)))
                    }
                })
                break;
            case "experience":
                data.forEach(dataItem => {
                    if (dataItem.type == "experience") {
                        inputDiv.push(edExp("experience", dataItem.data, data.indexOf(dataItem)))
                    }
                })
                break;
            case "qualification":
                data.forEach(dataItem => {
                    if (dataItem.type == "qualification") {
                        inputDiv.push(edExp("qualification", dataItem.data, data.indexOf(dataItem)))
                    }
                })
                break;
            default:
                break;
        }
        return inputDiv
    }

    return (
        <section className="section" id="experience">
            {loading ? <Splash/>: null }
            <div className="heading">
                <h1>Education, Experience & Qualifications</h1>
                <button className="update-button" onClick={()=>{updateSite()}}>Update {updating ? <div className="updating"></div> : null}</button>
            </div>
            <div className="ed-exp education">
                <h2 className="sub-heading">Education</h2>
                <div className="ed-exp-edit-container">
                    {fillInData("education")}
                    <button className="add-button" onClick={()=>{addData("education")}}>Add</button>
                </div>
            </div>
            <div className="ed-exp experience">
                <h2 className="sub-heading">Experience</h2>
                <div className="ed-exp-edit-container">
                    {fillInData("experience")}
                    <button className="add-button" onClick={()=>{addData("experience")}}>Add</button>
                </div>
            </div>
            <div className="ed-exp qualifications">
                <h2 className="sub-heading">Qualifications & Certifications</h2>
                <div className="ed-exp-edit-container">
                    {fillInData("qualification")}
                    <button className="add-button" onClick={()=>{addData("qualification")}}>Add</button>
                </div>
            </div>
        </section>
    )
}