import profImg from "../images/prof-img.png"
import { useEffect, useState } from "react"
import axios from "axios"
import Splash from "./Splash"

let token = sessionStorage.getItem("token")

export default function About() {
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)

    function reRender () {
        setCount(count + 1)
    }

    function updateSite() {
        setUpdating(true)
        axios.put('https://backend.dmurenzi.com/about/1', {
            name: name,
            profile_image: image,
            title: title,
            tag: tag,
            bio: bio,
            contact_info: contactInfo
        }, {
            headers: {
                'Authorization': token
            }
        })
        .then((res)=>{
            setUpdating(false)
            if (res.status == 200) {
                reRender()
            }
        })
    }

    const [componentData, setComponentData] = useState({
        name: "",
        profile_image: "",
        title: "",
        tag: "",
        bio: "",
        contact_info: []
    })

    useEffect(() => {
        const fetchData = () => {
            axios.get('https://backend.dmurenzi.com/about/1')
            .then((response)=>{
                setLoading(false)
                setName(response.data.name)
                if (response.data.profile_image.startsWith("https://dmurenzi.com/images/")) {
                    setImage(response.data.profile_image)
                } else {
                    setImage("https://dmurenzi.com/images/" + response.data.profile_image)
                }
                setTitle(response.data.title)
                setTag(response.data.tag)
                setBio(response.data.bio)
                setContactInfo(JSON.parse(response.data.contact_info))
            })
        };

        fetchData();
    }, [])
    
    // let componentData = {
    //     name: "Daniel Murenzi",
    //     profile_image: "https://dmurenzi.com/images/dan.jpg",
    //     title: "Principal Information Technology Officer",
    //     tag: "Enginner",
    //     bio: "Daniel Murenzi is the Principal Information Technology Officer for the East African Community. He is a seasoned computer Engineer and he holds MSc in Communication Management with more than 19 years of deep technical experience developing, implementing and supporting complex digital projects in areas of ICT enterprise architecture and e-government projects (Telecom, Health, Fintech, Agriculture, trade, customs, cyber security systems) and implementing them. Daniel is a successful Technologist who has led digital transformation in East African Community, developed digital projects, agile management, and digital innovation in multiple sectors. He has transformed underperforming sectors of EAC by delivering new digital solutions, technology advisory role to the management, enabling agile practices, outsourcing commodity services and establishing performance metrics.",
    //     contact_info: [
    //         {
    //             type: "phone-number",
    //             input: "0786527164"
    //         },
    //         {
    //             type: "email",
    //             input: "norman135st@gmail.com"
    //         },
    //         {
    //             type: "physical",
    //             input: "Kigali, Rwanda"
    //         }
    //     ]
    // }

    const [name, setName] = useState(componentData.name)
    const [image, setImage] = useState(componentData.profile_image)
    const [title, setTitle] = useState(componentData.title)
    const [tag, setTag] = useState(componentData.tag)
    const [bio, setBio] = useState(componentData.bio)
    const [contactInfo, setContactInfo] = useState(componentData.contact_info)

    function addContactInfo (type) {
        let info = contactInfo
        info.push({
            type: type,
            input: null
        })
        setContactInfo(info)
        reRender()
    }

    function updateContactInfo (key, data) {
        let info = contactInfo
        info[key].input = data
        setContactInfo(info)
        reRender()
    }

    function removeContactInfo (key) {
        let info = contactInfo
        info.splice(key, 1)
        setContactInfo(info)
        reRender()
    }
    
    function contactInfoInput (type, input, key) {
        let className = type + "-input-div contact-info-input-div"
        
        let inputType = "text"
        let placeholder = "Fill in"

        if (type == "phone-number") {
            inputType = "tel"
            placeholder = "Phone Number"
        } else if (type == "email") {
            inputType = "email"
            placeholder = "Email Address"
        } else if (type == "physical") {
            inputType = "text"
            placeholder = "Physical Address"
        }

        return (
            <div className={className} key={key}>
                <input type={inputType}
                    className={type}
                    placeholder={placeholder}
                    value={input}
                    onChange={(e)=>{updateContactInfo(key, e.target.value)}}
                    />
                <button className="delete-button" onClick={()=>{removeContactInfo(key)}}>-</button>
            </div>
        )
    }


    

    function fillInContactInfo(type) {
        let inputDiv = []
        switch (type) {
            case "phone":
                contactInfo.forEach(contactInfoItem => {
                    if (contactInfoItem.type == "phone-number") {
                        inputDiv.push(contactInfoInput(contactInfoItem.type, contactInfoItem.input, contactInfo.indexOf(contactInfoItem)))
                    }
                })
                break;
            case "email":
                contactInfo.forEach(contactInfoItem => {
                    if (contactInfoItem.type == "email") {
                        inputDiv.push(contactInfoInput(contactInfoItem.type, contactInfoItem.input, contactInfo.indexOf(contactInfoItem)))
                    }
                })
                break;
            case "physical":
                contactInfo.forEach(contactInfoItem => {
                    if (contactInfoItem.type == "physical") {
                        inputDiv.push(contactInfoInput(contactInfoItem.type, contactInfoItem.input, contactInfo.indexOf(contactInfoItem)))
                    }
                })
                break;
            default:
                break;
        }
        return inputDiv
    }

    
    return (
        <section className="section" id="about">
            {loading ? <Splash/>: null }    
            <div className="heading">
                <h1>User Profile</h1>
                <button className="update-button" onClick={() => {updateSite()}}>Update {updating ? <div className="updating"></div> : null}</button>
            </div>
            <div className="user-profile">
                <div className="img-name-title">
                    <label htmlFor="editImg" className="img-div">
                        {image && <img src={image}/>}
                    </label>
                    <input
                        type="file"
                        id="editImg"
                        className="img-input"
                        onChange={(e)=>{
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onload = (event) => {
                                setImage(event.target.result)
                            }
                            reader.readAsDataURL(file)
                        }}/>
                    <div className="name-title">
                        <input type="text"
                            className="name"
                            placeholder="Name"
                            value={name}
                            onChange={(e)=>{setName(e.target.value)}}
                        />
                        <input type="text"
                            className="title"
                            placeholder="Title"
                            value={title}
                            onChange={(e)=>{setTitle(e.target.value)}}
                        />
                        <input type="text"
                            className="tag"
                            placeholder="Tag"
                            value={tag}
                            onChange={(e)=>{setTag(e.target.value)}}
                        />
                    </div>
                </div>
                <div className="bio">
                    <textarea
                        className="bio-input"
                        placeholder="Bio"
                        value={bio}
                        onChange={(e)=>{setBio(e.target.value)}}    
                    />
                </div>
            </div>
            <h2 className="sub-heading">Contact Information</h2>
            <div className="contact">
                <div className="contact-info phone-numbers">
                    <h4 className="sub-heading">Phone Numbers</h4>
                    {fillInContactInfo("phone")}
                    <button className="add-button" onClick={() => {addContactInfo("phone-number")}}>+</button>
                </div>
                <div className="contact-info emails">
                    <h4 className="sub-heading">Email Addresses</h4>
                    {fillInContactInfo("email")}
                    <button className="add-button" onClick={() => {addContactInfo("email")}}>+</button>
                </div>
                <div className="contact-info physical-addresss">
                    <h4 className="sub-heading">Physical Addresses</h4>
                    {fillInContactInfo("physical")}
                    <button className="add-button" onClick={() => {addContactInfo("physical")}}>+</button>
                </div>
            </div>
        </section>
    )
}