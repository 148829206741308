import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

let token = sessionStorage.getItem("token")

// Authorize
async function authorize() {
    await axios.get("https://backend.dmurenzi.com/users", {
        headers: {
            "Authorization": token
        }
    }).catch(err => {
      console.log(err)
      // Send to login page
      // window.location.href = "http://localhost:5500/login.html"
    })
}

authorize()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);