import { useState } from "react";
import Navigation from "./components/Navigation";
import Main from "./Main";



export default function App() {
    return (
        <div className="container">
            <Navigation/>
            <Main/>
        </div>
    )
}
