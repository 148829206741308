import About from "./components/About";
import Experience from "./components/Experience";
import { Routes, Route, BrowserRouter } from "react-router-dom";

export default function Main() {
    return (
        <main>
                <Routes>
                    <Route exact path="/" element={<About/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/experience" element={<Experience/>} />
                </Routes>
        </main>
        
    )
}